.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.offcanvas-body {
  background-color: #f8dcf4;
  color: whitesmoke;
}

.offcanvas-header {
  background-color: #e6afdc;
  color: white;
  font-family: "CatchyMelody";
  height: 95px;
}

#offcanvasNavbarLabel {
  font-size: 2em;
}



.btn-close {
  background-color: white;
  opacity: 0.1;
}

.align-top {
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.align-top:hover {
  animation-name: bounce;
  animation-timing-function: ease;
}

@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-50px); }
  100% { transform: translateY(0); }
}

.navbar {
  background-color: #f8dcf4!important;
}

.navbar-toggler {
  border: none;
  box-shadow: none;
  transition: opacity 0.5s ease;
}

.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

.navbar-toggler:hover {
  opacity: 0.85;
}


#walletButton {
  transition: opacity 0.5s ease;
}

#walletButton:hover {
  opacity: 0.7;
}

