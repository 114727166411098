

.headline {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 10vw;
}

.inset {
  position: absolute;
  inset: 0;
}
.white {
  color: white;
}
.gray {
  color: #446;
}

.full {
  height: 100vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient {
  background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, black);
}

.header-logo {
  text-align: center;
  font-size: 10em;
  font-family: 'CatchyMelody';
  color:#f8dcf4;
  text-shadow: 0 0 1px #959595, 0 0 7px darkgrey;

  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.header-logo:hover {
  animation-name: scaley;
  animation-timing-function: ease;
}

@keyframes scaley {
  0%   { transform: scale(1); }
  50%  { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@media (max-width: 768px) 
{
   .header-logo {
    font-size: 5em;
   }
}
