
@keyframes scaley {
    0%   { transform: scale(1); }
    50%  { transform: scale(1.5); }
    100% { transform: scale(1); }
}

.player > img:hover {
    width: 200px;
}

@media (max-width: 768px) 
{
    .player > img {
        width: 80px;
        transition: 0.7s;
    }
    .player > img:hover {
        width: 120px;
    }
}
