@font-face {
  font-family: 'CatchyMelody';
  src: local('CatchyMelody'), url(./CatchyMelody.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./Poppins-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5EAEA;
}

html,
body {
  padding: 0;
  margin: 0;
  background: rgb(252, 237, 252);
}

main {
  height: 300vh;
}

p {
  font-family: "Poppins", sans-serif;
}

.banner {
  height: 100vh;
  background: #bbe8f6;
}