.mint {
    width: 100%;
}


.about {
    padding-top: 100px;
    width: 100%;
    text-align: center;
    background-color: rgb(252, 237, 252);
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 10px;
    align-items: center;
}

.container {
    max-width: 900px;
}

.container>h1 {
    font-family: "CatchyMelody";
    font-size: 5em;
    letter-spacing: 2px;
    color: #f8bbed;
    text-align: center;
}

.container>h3 {
    letter-spacing: 2px;
    font-size: 1.8em;
    color: #282c34;
    padding-top: 15px;
}

.about-circles {
    width: 100%;
    margin-top: 20px;
    height: 150px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@keyframes scaley {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.about-circle {
    width: 125px;
    border: 2px solid #7e6d7b;
    box-shadow: -3px 5px #888888;
    margin-top: 40px;
    height: 125px;
    border-radius: 100px;
    background-size: 150px;
    background-color: whitesmoke;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.about-circle:hover {
    animation-name: scaley;
    animation-timing-function: ease;
}

.total {
    text-align: center;
}

.mint-select {
    width: 300px;
    z-index: 1;
}

.info {
    text-align: center;
    margin: 0 auto;
    z-index: 2;
}

.progress {
    width: 300px;
    height: 35px;
    margin: 0 auto;
    z-index: 1;
}

.minter {
    margin: 0 auto;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    color: black;
}

.btn2 {
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    background-color: black;
    border-color: gray;
    z-index: 1;
}

.slideshowGIF {
    width: 300px;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    border: 3px solid gray;
}

.select2 {
    max-width: 200px;
}

.mint-action {
    display: flex;
    justify-content: space-between;
    z-index: 1;
}

.mint-button {
    margin-top: 0px;
    height: 45px;
    z-index: 1;
    background-color: thistle !important;
    border: none
}

.socialContainer {
    width: 100%;
    padding: 30px;
    padding-bottom: 0px;
    z-index: 1;
    display: flex;
    justify-content: right;
}

.social:hover {
    text-decoration: none;
    opacity: 0.5;
    filter: alpha(opacity=50);
    z-index: 1;
}

.social {
    transition: 0.7s;
    text-decoration: none;
    color: #282c34 !important;
    z-index: 1;
}

.footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    background-color: rgb(252, 237, 252);
    box-shadow: 0px -10px 5px rgb(252, 237, 252);
    font-size: 0.75em;
}

.footerText {
    padding-top: 30px;
    padding: 50px;
    max-width: 1000px;
    min-width: 350px;
    text-align: left;
}

.footerDisc {
    text-decoration: none;
}

.footerDisc:hover {
    text-decoration: none;
    color: gray;
    z-index: 1;
}

.footer-button {
    background-color: #C7C4E2;
    border: none;
    border-radius: 5px;
    height: 50px;
    font-size: 18px;
    z-index: 1;
    margin-right: 20px;
    margin-bottom: 20px;
}

.listItem {
    font-size: 22px;
    font-weight: 300;
    z-index: 1;
}

.progress-bar {
    background-color: #C7C4E2 !important;
    z-index: 1;
}

#walletButton {
    background-color: thistle !important;
    border: none;
    font-size: 18px;
}

.animated-left {
    max-width: 800px;
    max-height: 800px;
}


@media (max-width: 768px) {
    .animated-left {
        max-width: 300px;
        max-height: 300px;
    }

    .container>h1 {
        font-size: 3em;
    }

    .container>h3 {
        font-size: 1.4em;
    }
}

.overview {
    display: flex;
    justify-content: center;
    column-gap: 100px;
    color: #282c34;
    padding-left: 30px;
    padding-right: 30px;
    flex-wrap: wrap;
    max-width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: rgb(252, 237, 252);
}

.overviewText {
    padding-top: 30px;
    height: 650px;
    padding: 20px;
    width: 50vw;
    max-width: 400px;
    min-width: 350px;
    text-align: left;
    border-radius: 15px;
    background-color: rgba(36, 36, 36);
    margin-bottom: 50px;
    flex-grow: 3;
    margin-bottom: 30px;
    transition: 0.5s;
}

.overviewText:hover {
    margin-top: -10px;
    margin-bottom: 40px;
}

.listItem {
    font-size: 22px;
    font-weight: 300;
}

h1 {
    font-size: 3em;
}

.aboutUsText>p {
    font-size: 22px;
    font-weight: 300;

}


.logo {
    width: 50vw;
    max-width: 750px;
    min-width: 350px;
    padding-top: 30px;
}

.selected {
    padding-bottom: 5px;
    border-bottom-style: solid;
    border-bottom-width: 3.1px;
    width: fit-content;
}

.joinDisc {
    text-decoration: none;
    color: rgb(242, 255, 252);
    font-weight: 300;
}

.joinDisc:hover {
    text-decoration: none;
    color: rgb(242, 255, 252);
    font-weight: 300;
}

.footerText > h1 {
    font-family: "CatchyMelody";
    font-size: 5em;
    letter-spacing: 2px;
    color: #f8bbed;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    color: #282c34;
    background-color: rgb(252, 237, 252);
    margin-bottom: 30px;
    margin-top: 100px;
}

.footerText {
    padding-top: 30px;
    padding: 50px;
    max-width: 1000px;
    min-width: 350px;
    text-align: left;
}

.footerDisc {
    text-decoration: none;
    color: rgba(36, 36, 36) !important;
}

.footerDisc:hover {
    text-decoration: none;
    color: gray;
}

.footer-button {
    border-color: rgb(40, 58, 54) !important;
}

.social {
    transition: 0.7s;
    text-decoration: none;
    color: #f8bbed !important;
}

.file {
    padding-left: 20px;
}

.social:hover {
    text-decoration: none;
    opacity: 0.5;
    filter: alpha(opacity=50);
}

.fa-discord {
    margin-right: 50px;
}

.socialContainer {
    width: 100%;
    padding: 30px;
}



.team {
    background-color: white;
    color: #282c34;
    padding-bottom: 20px;
    font-size: 1em;
}

.team-link {
    color: #f8bbed;
    text-decoration: none;
}

.team-link:hover {
    color: #f8bbed;
    ;
}

.team > h1 {
    font-family: "CatchyMelody";
    font-size: 5em;
    letter-spacing: 2px;
    color: #f8bbed;
}

.team-img {
    width: 150px;
    border-radius: 10px;
    float: right;
    border: 1px solid #f8bbed;
}

.team-header {
    padding: 20px;
    margin-top: 150px;
    margin-bottom: 30px;

    font-family: "CatchyMelody";
    font-size: 5em;
    color: #f8bbed;
    text-align: center;
}

.player {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.player > img {
    width: 150px;
    transition: 0.7s;
    opacity: .85;
    z-index: 1;
}
